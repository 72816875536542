<template>
    <div class="waiting-rooms">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Salas de Espera',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para recepciones en la ciudad de Bucaramanga. mas de 30 años de experiencia fabricando muebles para recepción' },
			{ name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Salas de Espera'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Salas de Espera'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Salas de Espera'},
			{itemprop: 'description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Salas de espera',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'Sala 1',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/salas-de-espera/productos/sala_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/salas-de-espera/productos/sala_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Sala 2',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/salas-de-espera/productos/sala_2.jpg',
                        slides: [
                            'mobiliario-de-oficina/salas-de-espera/productos/sala_2.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Sala 3',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/salas-de-espera/productos/sala_3.jpg',
                        slides: [
                            'mobiliario-de-oficina/salas-de-espera/productos/sala_3.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ],
            }
        }
    }
}
</script>